<template>
  <div class="vx-row">
    <div class="vx-col md:w-2/3 w-full">
      <vx-card class="mb-4">
        <marquee-text class="pl-24" :repeat="20" :duration="50">
          <h3>{{notice}}</h3>
        </marquee-text>
      </vx-card>
      <vx-card>
        <div class="vx-row">
          <div class="vx-col md:w-2/3 w-full">
            <h4>จัดการบริหารรอบใบเสร็จ</h4>
          </div>
          <div class="vx-col md:w-1/3 w-full text-right">
            <vs-button v-if="position !== 'แผนก IT'" @click="submitChangeBill" :disabled="disableBtn" class="mb-2">เปลี่ยนรอบบิล</vs-button>
            <vs-button v-if="position === 'แผนก IT'" @click="submitChangeBill" class="mb-2">เปลี่ยนรอบบิล</vs-button>
          </div>
        </div>
        <div class="vx-row my-5">
          <div class="vx-col md:w-1/4 w-full text-right ">
            รหัสรอบบิลปัจจุบัน:
          </div>
          <div class="vx-col md:w-2/4">
            <vs-input class="input-bill" v-model="billIdNow.billId" readonly/>
          </div>
          <div class="vx-col md:w-1/4 w-full">
            {{billIdNow.name}}
          </div>
        </div>
        <div v-if="position !== 'แผนก IT'" class="vx-row my-5">
          <div class="vx-col md:w-1/4 w-full text-right ">
            บันทึกมิเตอร์แล้ว:
          </div>
          <div class="vx-col md:w-2/4">
            <vs-input class="input-bill" v-model="billIdNow.meter" readonly/>
          </div>
        </div>
        <div v-if="position === 'แผนก IT'" class="vx-row my-5">
          <div class="vx-col md:w-1/4 w-full text-right ">
            บันทึกมิเตอร์แล้ว:
          </div>
          <div class="vx-col md:w-2/4">
            <vs-input class="input-bill" v-model="billIdNow.meter"/>
          </div>
        </div>
        <div v-if="position !== 'แผนก IT'" class="vx-row my-5">
          <div class="vx-col md:w-1/4 w-full text-right ">
            จำนวนผู้ใช้น้ำจริง:
          </div>
          <div class="vx-col md:w-2/4">
            <vs-input class="input-bill" v-model="billIdNow.user" readonly/>
          </div>
        </div>
        <div v-if="position === 'แผนก IT'" class="vx-row my-5">
          <div class="vx-col md:w-1/4 w-full text-right ">
            จำนวนผู้ใช้น้ำจริง:
          </div>
          <div class="vx-col md:w-2/4">
            <vs-input class="input-bill" v-model="billIdNow.user"/>
          </div>
        </div>
        <div class="vx-row my-5">
          <div class="vx-col md:w-1/4 w-full text-right ">
            รอบบิลต่อไป:
          </div>
          <div class="vx-col md:w-2/4">
            <vs-input class="input-bill" v-model="billIdNext.billId" readonly/>
          </div>
        </div>
        <div class="vx-row my-5" v-if="check">
          <div class="vx-col sm:w-1/1 w-full">
            <span class="text-red align-text-bottom">*</span>
            <span class="text-red">จำนวนผู้ใช้น้ำจริงจะแสดง เมื่อจดมิเตอร์คนแรก</span>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col md:w-1/3 w-full">
      <vx-card title="รอบบิลต่อไป">
        <div class="vx-row mb-4">
          <div class="vx-col md:w-2/5 w-full text-right">
            ลำดับที่:
          </div>
          <div class="vx-col md:w-3/5 w-full">
            {{billIdNext.no}}
          </div>
        </div>
        <div class="vx-row my-4">
          <div class="vx-col md:w-2/5 w-full text-right">
            รหัสรอบบิล:
          </div>
          <div class="vx-col md:w-3/5 w-full">
            {{billIdNext.billId}}
          </div>
        </div>
        <div class="vx-row my-4">
          <div class="vx-col md:w-2/5 w-full text-right">
            ชื่อรอบบิล:
          </div>
          <div class="vx-col  md:w-3/5 w-full">
            {{billIdNext.name}}
          </div>
        </div>
        <div class="vx-row my-4">
          <div class="vx-col md:w-2/5 w-full text-right">
            ปีงบประมาณ:
          </div>
          <div class="vx-col md:w-3/5 w-full">
            {{billIdNext.year}}
          </div>
        </div>
<!--        <div class="vx-row my-4">-->
<!--          <div class="vx-col md:w-2/5 w-full text-right">-->
<!--            สถานะรอบ:-->
<!--          </div>-->
<!--          <div class="vx-col md:w-3/5 w-full">-->
<!--            {{bill.next.CB05}}-->
<!--          </div>-->
<!--        </div>-->
      </vx-card>
    </div>
  </div>
</template>
<script>
  import moduleBillConfig from "@/store/billconfig/moduleBillConfig.js";

  export default {
    components: {},
    data() {
      return {
        bill: {},
        disableBtn: true,
        check: true,
        billId: '',
        position: '',
        billIdNext: {
          no: '',
          billId: '',
          name: '',
          year: ''
        },
        billIdNow: {
          billId: '',
          meter: '',
          user: '',
          name: ''
        },
        notice:'***ให้กำหนดเปลี่ยนรอบบิลเดือนละ 1 ครั้ง หากจำนวนที่บันทึกมิเตอร์แล้วกับจำนวนผู้ใช้น้ำจริงไม่เท่ากัน กรุณาตรวจสอบ รายชื่อผู้ใช้น้ำที่แอปบนมือถือ เมนูจดมิเตอร์ทุกเส้นทางว่ายังมีรายชื่อค้างอยู่หรือไม่ ถ้าไม่มีแล้วให้ติดต่อผู้ดูแล***'
      }
    },
    watch: {
      bill(val) {
        if (val.bookListCount === val.current.CB06) {
          if((val.bookListCount === 0 || val.bookListCount === '0') && (val.current.CB06 === 0 || val.current.CB06 === '0')) {
            this.disableBtn = true;
            this.check = true;
          } else {
            this.disableBtn = false;
            this.check = false;
          }
        } else {
          this.disableBtn = true;
          this.check = false;
        }
      }
    },
    methods: {
     async init() {
        const bill = await this.$store.state.billConfigs.item;
        this.bill = await this.$store.state.billConfigs.item;
        this.billIdNext = {
          no: bill.next.CB02,
          billId:  bill.next.CB01,
          name:  bill.next.CB04,
          year:  bill.next.CB03
        };
        this.billIdNow = {
          billId: bill.current.CB01,
          meter: bill.bookListCount,
          user: bill.current.CB06,
          name:  bill.current.CB04
        };
        this.$vs.loading.close()
      },
      submitChangeBill() {
        this.$vs.dialog({
          type: 'confirm',
          title: `กำหนดรอบบิล`,
          text: 'ต้องการเปลี่ยนรอบบิลใช่หรือไม่\nหมายเหตุ เมื่อเปลี่ยนรอบบิลได้แล้วท่านจะต้องเพิ่มและยกเลิกผู้ใช้น้ำให้เรียบร้อย\nก่อนจะออกจดเลขมาตรคนแรกห้ามลืมเด็ดขาด',
          accept: this.changeBillConfig
        })
      },
      async changeBillConfig() {
        this.disableBtn = true
        this.$vs.loading();
        await this.$store.dispatch("billConfigs/updateData").then((res) => {
          if (res.data.status) {
            this.init();
          } else {
            this.$vs.loading.close()
          }
        }).catch(error => {
          this.$vs.loading.close()
        });
      }
    },
    async created() {
      const user = JSON.parse(sessionStorage.getItem('vuex'));
      this.position = user.owner.user.user.positionname;
      this.$vs.loading();
      if (!moduleBillConfig.isRegistered) {
        this.$store.registerModule('billConfigs', moduleBillConfig);
        moduleBillConfig.isRegistered = true;
      }
      await this.$store.dispatch("billConfigs/fetchDataItem")
      this.init();
    }
  }
</script>
<style lang="scss">
  .input-bill {
    width: 260px;
  }
</style>
